import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Input,
  FormGroup,
  Label,
  ButtonGroup,
  Row,
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { fetchFleetListTable } from "../../../redux/actions/transporter/aTransporter-FleetListTable";
import { fetchDeleteItem } from "../../../redux/actions/transporter/aTransporter-DeleteItem";
import { fetchOBDTypeTable } from "../../../redux/actions/transporter/aTransporter-OBDTypeTable";
import { toast } from "react-toastify";
import * as API_LINKS from "../../../redux/config/links";
import axios from "axios";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { Link } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FleetDetails from "./FleetDetails";
import FleetHistoryObdModal from "./FleetHistoryObdModal";
import { AbilityContext } from "../../../casl/Can";
import NumberFormat from "react-number-format";
import GenerateFleetQr from "./GenerateFleetQr";
import MaintenanceBinding from "./MaintenanceBinding";
import InputImage from "../../../components/InputImage/InputImage";
import { onFetch, onFetchAll } from "../../../helpers/helper";
import LoadingContent from "../../../components/LoadingContent/LoadingContent";
import { activeFeature } from "src/constants/constants";
import CheckSheetWeekly from "./CheckSheetWeekly/CheckSheetWeekly";

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

class FleetDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Modal State
      modalBindOBD: false,
      modalUnbindOBD: false,
      modalSendReport: false,

      //Bind OBD
      selectedFleetID: 0,
      selectedOBDType: 0,
      selectedOBDCode: "",
      selectedRow: {},

      obdTypeList: [],
      isBinding: false,
      // original date
      startdate: "",
      enddate: "",
      fuelCost: 0,
      fuelUsage: 0.0,
      fuelCostError: "",
      fuelUsageError: "",
      // converted
      convStartDate: "",
      convEndDate: "",

      //For add state
      selectedBrands: {},
      brandsList: [],

      selectedType: {},
      typeList: [],

      selectedGeneralType: {},
      generalTypeList: [],

      selectedFuel: {},
      fuelList: [],

      selectFleet: [],

      inputImageValue: [
        {
          image: "",
          desc: "",
        },
      ],

      remoteImages: null,
      loadingRemoteImages: false,

      inputImageQrSolar: null,

      selectedIdObd: null
    };
  }

  UNSAFE_componentWillMount = () => {
    this.props.fetchFleetListTable();
    this.props.fetchOBDTypeTable();
  };

  getImages = (id) => {
    onFetch(
      {
        url: API_LINKS.FLEET_LIST_IMAGE_URL,
        data: {
          id_fleet: id,
        },
      },
      {
        beforeSend: () => {
          this.setState({
            loadingRemoteImages: true,
          });
        },
        success: (response) => {
          this.setState({
            loadingRemoteImages: false,
            inputImageValue: response.data.result.map((item) => ({
              image: item.link,
              desc: item.desc,
            })),
          });
        },
        error: () => {
          this.setState({
            loadingRemoteImages: false,
          });
        },
      }
    );
  };

  toggleBindCheckSheet = (row = null) => {
    this.setState({ modalBindCheckSheet:!!row? {fleet:row, }:null });
    if (!!this.modalBindCheckSheet) {
    }
    else {
    }
  }

  toggleOpenEdit = (_row) => {
    this.loadPropertiesDatatoSelect();
    this.getImages(_row.id);
    this.setState({
      selectedId: _row.id,
      selectedRow: _row,
      reg_year: _row.reg_year,
      manufacture_year: _row.manufacture_year,
      selectedGeneralType: _row.id_car_general_type,
      selectedBrands: _row.id_car_brands,
      selectedType: _row.id_car_type,
      selectedFuel: _row.id_fuel_type,
      fuel_consumption: _row.fuel_consumption,
      odometer: _row.odometer,
      max_load: _row.max_tonnage,
      mt_speed_reduction: _row.mt_speed_reduction,
      rest_interval: _row.rest_interval,
      interval_between_rest: _row.interval_between_rest,
      full_tank_voltage: _row.full_tank_voltage,
      empty_tank_voltage: _row.empty_tank_voltage,
      fuel_tank_capacity: _row.fuel_tank_capacity,
      fuel_alert_sensitivity: _row.fuel_alert_sensitivity,
      tkm_cost: _row.tkm_cost,
      empty_weight: _row.empty_weight,
      modalEdit: !this.state.modalEdit,
      door_number: _row.door_number,
      inputImageQrSolar: _row.qr_link_solar
    });
  };

  toggleCloseEdit = () => {
    this.setState({
      selectedId: 0,
      selectedRow: {},
      fuel_consumption: 0,
      odometer: 0,
      taxDate: null,
      taxExpiryDate: null,
      pviDate: null,
      pviExpiryDate: null,
      full_tank_voltage: 0,
      empty_tank_voltage: 0,
      fuel_tank_capacity: 0,
      fuel_alert_sensitivity: 0,
      tkm_cost: 0,
      empty_weight: 0,
      door_number: null,
      modalEdit: false,
    });
  };

  toggleOpenSendReport = (_id, _regNumber) => {
    this.setState({
      selectedFleetID: _id,
      selectedRegNumber: _regNumber,
      modalSendReport: !this.state.modalSendReport,
    });
  };

  toggleCloseSendReport = () => {
    this.setState({
      selectedFleetID: 0,
      selectedRegNumber: "",
      modalSendReport: !this.state.modalSendReport,
    });
  };

  toggleOpenBindOBD = (_fleetID, _regNumber) => {
    this.setState({
      modalBindOBD: !this.state.modalBindOBD,
      selectedRegNumber: _regNumber,
      selectedFleetID: _fleetID,
    });
    this.loadOBDTypeDatatoSelect();
  };

  toggleCloseBindOBD = () => {
    this.setState({
      modalBindOBD: !this.state.modalBindOBD,
      selectedFleetID: 0,
      selectedOBDType: 0,
      selectedOBDCode: "",
      selectedRegNumber: "",
    });
  };

  toggleOpenDelete = (_row) => {
    // console.log(_row);
    this.setState({
      selectedId: _row.id,
      selectedName: _row.reg_number_with_door,
      selectedIdObd: _row.id_obd,
      modalDelete: !this.state.modalDelete,
    });
  };

  toggleCloseDelete = () => {
    this.setState({
      selectedId: 0,
      selectedName: "",
      selectedIdObd: null,
      modalDelete: !this.state.modalDelete,
    });
  };

  toggleOpenUnbindOBD = (_id, _regNumber, _obdCode) => {
    this.setState({
      selectedId: _id,
      selectedRegNumber: _regNumber,
      selectedOBDCode: _obdCode,
      modalUnbindOBD: !this.state.modalUnbindOBD,
    });
  };

  toggleCloseUnbindOBD = () => {
    this.setState({
      selectedId: 0,
      selectedRegNumber: "",
      selectedOBDCode: "",
      modalUnbindOBD: !this.state.modalUnbindOBD,
    });
  };

  toggleOpenMaintenanceBinding = (_id, _regNumber) => {
    this.setState({
      selectedId: _id,
      selectedRegNumber: _regNumber,
      modalMaintenanceBinding: !this.state.modalMaintenanceBinding,
    });
  };

  toggleCloseMaintenanceBinding = () => {
    this.setState({
      selectedId: 0,
      selectedRegNumber: "",
      modalMaintenanceBinding: !this.state.modalMaintenanceBinding,
    });
    this.props.refreshMaintenanceDashboard();
  };

  loadOBDTypeDatatoSelect = () => {
    var list = [];
    if (this.props.obdTypeTableData.result.length !== 0) {
      var obdTypeTableData = this.props.obdTypeTableData.result;
      // console.log(obdTypeTableData)
      obdTypeTableData.forEach((p, index) => {
        var obdType = {
          label: obdTypeTableData[index].name,
          value: obdTypeTableData[index].id,
        };
        list.push(obdType);
      });
    }
    this.setState({ obdTypeList: list });
  };

  buttonFormatter = (cell, row) => {
    //

    return (
      <div>
        <ButtonGroup>
          {this.context.can("view", "edit_button@fleets_datatable") && (
            <React.Fragment>
              <Button
                className="btn btn-warning"
                size="sm"
                onClick={() => this.toggleOpenEdit(row)}
              >
                <i className="fa fa-edit"></i> Edit
              </Button>
              &nbsp;&nbsp;
            </React.Fragment>
          )}
          {this.context.can("view", "edit_button@fleets_datatable") && activeFeature.CHECKSHEET_WEEKLY &&(
            <React.Fragment>
              <Button onClick={() => this.toggleBindCheckSheet(row)}>
                Checksheet Weekly
              </Button>
              &nbsp;&nbsp;
            </React.Fragment>
          )}
          {row.id_obd == null || row.obd_code == null
            ? this.context.can("view", "bind_obd_button@fleets_datatable") && (
                <React.Fragment>
                  <Button
                    className="btn btn-warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenBindOBD(row.id, row.reg_number_with_door)
                    }
                  >
                    <i className="fa fa-cog"></i> Bind OBD
                  </Button>{" "}
                  &nbsp;&nbsp;
                </React.Fragment>
              )
            : this.context.can(
                "view",
                "unbind_obd_button@fleets_datatable"
              ) && (
                <React.Fragment>
                  <Button
                    className="btn btn-warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenUnbindOBD(
                        row.id,
                        row.reg_number_with_door,
                        row.obd_code
                      )
                    }
                  >
                    <i className="fa fa-cog"></i> Unbind OBD
                  </Button>{" "}
                  &nbsp;&nbsp;
                </React.Fragment>
              )}
          {/* <ButtonGroup>
                            <Link to={'/transporter/fleets/report/'+row.id}>
                                <Button className="btn btn-success" color="success" size="sm"><i className="fa fa-eye"></i> Report</Button>
                            </Link>
                        </ButtonGroup> */}
          <FleetHistoryObdModal
            id_fleet={row.id}
            reg_number={row.reg_number_with_door}
          />
          &nbsp;&nbsp;
          <Button
            className="btn btn-info"
            size="sm"
            onClick={() =>
              this.toggleOpenSendReport(row.id, row.reg_number_with_door)
            }
          >
            <i className="fa fa-envelope"></i> Send Report
          </Button>{" "}
          &nbsp; &nbsp;
          {this.context.can("view", "delete_button@fleets_datatable") && (
            <React.Fragment>
              <Button
                className="btn btn-danger"
                color="danger"
                size="sm"
                onClick={() =>
                  this.toggleOpenDelete(row)
                }
              >
                <i className="fa fa-trash"></i> Delete
              </Button>
            </React.Fragment>
          )}
        </ButtonGroup>
      </div>
    );
  };

  reportFormatter = (cell, row) => {
    return (
      <div>
        <Link to={"/transporter/fleets/report/" + row.id}>
          <Button className="btn btn-primary" color="primary" size="sm">
            <i className="fa fa-eye"></i> Report
          </Button>
        </Link>
      </div>
    );
  };

  dateFormatExpPajak = (cell, row) => {
    //    return moment(row.exp_pajak).format("YYYY-MM-DD")
    // eslint-disable-next-line
    if (row.exp_pajak == null) {
      return "";
    } else {
      return moment(row.exp_pajak).format("YYYY-MM-DD");
    }
  };

  dateFormatExpKir = (cell, row) => {
    // eslint-disable-next-line
    if (row.exp_kir == null) {
      return "";
    } else {
      return moment(row.exp_kir).format("YYYY-MM-DD");
    }
  };

  isExpandableRow = () => {
    return true;
  };

  expandComponent = (row) => {
    return (
      <div>
        <FleetDetails
          fleet_id={row.id}
          reg_number={row.reg_number}
          car_brands={row.car_brands}
          car_type={row.car_type}
          fuel_type={row.fuel_type}
          reg_year={row.reg_year}
          manufacture_year={row.manufacture_year}
          cyl_cap={row.cyl_cap}
          vehicle_id_number={row.vehicle_id_number}
          engine_number={row.engine_number}
          max_tonnage={row.max_tonnage}
          mt_speed_reduction={row.mt_speed_reduction}
          rest_interval={row.rest_interval}
          interval_between_rest={row.interval_between_rest}
          fuel_consumption={row.fuel_consumption}
          odometer={row.odometer}
          obd_code={row.obd_code}
          exp_pajak={row.exp_pajak}
          exp_kir={row.exp_kir}
          full_tank_voltage={row.full_tank_voltage}
          empty_tank_voltage={row.empty_tank_voltage}
          fuel_tank_capacity={row.fuel_tank_capacity}
          fuel_alert_sensitivity={row.fuel_alert_sensitivity}
          empty_weight={row.empty_weight}
          door_number={row.door_number}
          tkm_cost={row.tkm_cost}
          qr_link_solar={row.qr_link_solar}
        />
        {/* </div> */}
      </div>
    );
  };

  isRequestFleetChecked = (id) => {
    const { selectFleet } = this.state;
    let filterByValue = selectFleet.filter((v) => v.id === id);
    if (filterByValue.length > 0) return true;
    return false;
  };

  fleetSelectFormartter = (cell, row) => {
    const { selectFleet } = this.state;
    return (
      <Col md="12" xs="12" align="center">
        <Input
          id={row.id}
          type="checkbox"
          defaultChecked={this.isRequestFleetChecked(row.id)}
          onChange={(e) => {
            let checkBoxProp = e.target;
            if (!checkBoxProp.checked) {
              this.setState({
                selectFleet: selectFleet.filter((s) => s.id !== row.id),
              });
            }
            if (checkBoxProp.checked)
              this.setState({
                selectFleet: [...selectFleet, row],
              });
          }}
        />
      </Col>
    );
  };

  fleetRegisWithMaintenanceBtnFormatter = (cell, row) => {
    return (
      <>
        {cell}&nbsp;&nbsp;
        <Button
          color="primary"
          size="sm"
          onClick={() =>
            this.toggleOpenMaintenanceBinding(row.id, row.reg_number_with_door)
          }
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <i className="fa fa-wrench" style={{ color: "blue" }}></i>
        </Button>
      </>
    );
  };

  fleetSelectRentFormartter = (cell, row) => {
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    return (
      <Col md="12" xs="12" align="center">
        <Input
          id={row.id}
          type="checkbox"
          disabled={
            this.context.can("view", "edit_button@fleets_datatable")
              ? false
              : true
          }
          defaultChecked={cell === 1 ? true : false}
          onChange={(e) => {
            let isChecked = e.target.checked ? 1 : 0;
            axios({
              method: "post",
              url: API_LINKS.FLEET_EDIT_URL,
              headers: {
                Authorization: auth,
              },
              data: {
                id: row.id,
                show_rente: isChecked,
              },
            })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.status === "success") {
                    toast.success("Data successfully edited!");
                    this.props.fetchFleetListTable();
                  }
                }
              })
              .catch(function (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    return toast.error(
                      "Invalid username or password! Please try again!"
                    );
                  } else if (
                    error.response.status === 404 ||
                    error.response.status === 500
                  ) {
                    return toast.error(
                      "Server cannot be contacted! Please ask your system administrator!"
                    );
                  } else if (error.response.status === 400) {
                    return toast.error(error.response.data.message);
                  } else {
                    return toast.error(
                      "Something went wrong... Please try again later..."
                    );
                  }
                } else if (error.request) {
                  return toast.error(
                    "Request error! Please try again later..."
                  );
                } else {
                  return toast.error(
                    "Something went wrong... Please try again later..."
                  );
                }
              });
          }}
        />
      </Col>
    );
  };

  customBtnGroup = (props) => {
    const { selectFleet } = this.state;
    return (
      <div>
        <GenerateFleetQr fleets={selectFleet} />
        {props.exportCSVBtn}
      </div>
    );
  };

  selectedFleetCsvFormat = (cell) => {
    if (!cell) return "";
    return cell;
  };

  showDataTable = () => {
    var options = {
      noDataText: "No fleet registered on your dashboard. Try one-lah!",
      defaultSortName: "reg_number_with_door", // default sort column name
      defaultSortOrder: "asc", // default sort order
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "30",
          value: 30,
        },
      ],
      sizePerPage: 30,
      exportCSVSeparator: ";",
      btnGroup: this.customBtnGroup,
    };
    return (
      <BootstrapTable
        data={this.props.fleetListTableData}
        version="4"
        bordered={false}
        hover
        pagination
        search
        edit
        options={options}
        exportCSV={true}
        csvFileName={this.fileNameFormat}
        multiColumnSearch
        expandableRow={this.isExpandableRow}
        expandComponent={this.expandComponent}
        expandColumnOptions={{ expandColumnVisible: true }}
      >
        <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" hidden>
          ID
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="selectFleet"
          csvHeader="-"
          dataFormat={this.fleetSelectFormartter.bind(this)}
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="2%"
          csvFormat={this.selectedFleetCsvFormat.bind(this)}
        ></TableHeaderColumn>
        <TableHeaderColumn
          dataField="company"
          searchable
          dataSort
          csvHeader="Company"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          hidden={
            this.context.can("view", "company_field@fleets_datatable")
              ? false
              : true
          }
        >
          Company
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="reg_number_with_door"
          filter={{ type: "TextFilter", delay: 1000 }}
          searchable
          dataSort
          csvHeader="Registration Number"
          thStyle={{ whiteSpace: "normal" }}
          dataFormat={this.fleetRegisWithMaintenanceBtnFormatter.bind(this)}
          tdStyle={{ whiteSpace: "normal" }}
          width="12%"
        >
          Registration Number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id_car_brands"
          dataSort
          csvHeader="ID Car Brands"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          hidden
          export={false}
        >
          ID Car Brands
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="car_brands"
          filter={{ type: "TextFilter", delay: 1000 }}
          dataSort
          csvHeader="Car Brands"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          Car Brands
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id_car_type"
          dataSort
          csvHeader="ID Car Type"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          hidden
          export={false}
        >
          ID Car Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="car_type"
          filter={{ type: "TextFilter", delay: 1000 }}
          dataSort
          csvHeader="Car Type"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          Car Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id_fuel_type"
          dataSort
          csvHeader="ID Fuel Type"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          hidden
          export={false}
        >
          ID Fuel Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="fuel_type"
          filter={{ type: "TextFilter", delay: 1000 }}
          dataSort
          csvHeader="Fuel Type"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          Fuel Type
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="reg_year" dataSort csvHeader="Registration Year" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Reg. Year</TableHeaderColumn> */}
        {/* <TableHeaderColumn dataField="manufacture_year" dataSort csvHeader="Manufacture Year" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Man. Year</TableHeaderColumn> */}
        {/* <TableHeaderColumn dataField="cyl_cap" dataSort csvHeader="Cylinder Capacity" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Cylinder Capacity</TableHeaderColumn> */}
        {/* <TableHeaderColumn dataField="vehicle_id_number" dataSort csvHeader="Vehicle Identity Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">VIN</TableHeaderColumn>
              <TableHeaderColumn dataField="engine_number" dataSort csvHeader="Engine Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Engine Number</TableHeaderColumn> */}

        {/* <TableHeaderColumn dataField="max_tonnage" dataSort csvHeader="Max Load" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Max Load</TableHeaderColumn>
              <TableHeaderColumn dataField="mt_speed_reduction" dataSort csvHeader="Manual Speed Reduction" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Mt. Speed Reduction</TableHeaderColumn>
              <TableHeaderColumn dataField="rest_interval" dataSort csvHeader="Rest Interval" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Rest Interval</TableHeaderColumn>
              <TableHeaderColumn dataField="interval_between_rest" dataSort csvHeader="Interval Between Rest" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Interval Between Rest</TableHeaderColumn> */}

        <TableHeaderColumn
          dataField="fuel_consumption"
          filter={{ type: "NumberFilter", delay: 1000 }}
          dataSort
          csvHeader="Fuel Consumption"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          Fuel Consumption
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="odometer" dataSort csvHeader="Odometer" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Odometer</TableHeaderColumn> */}
        <TableHeaderColumn
          dataField="obd_code"
          filter={{ type: "TextFilter", delay: 1000 }}
          dataSort
          csvHeader="OBD Code"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          OBD Code
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="show_rente"
          dataFormat={this.fleetSelectRentFormartter.bind(this)}
          searchable
          dataSort
          csvHeader="Available for Rent"
          filter={{
            type: "SelectFilter",
            options: { 0: "No", 1: "Yes" },
            condition: "eq",
          }}
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="7%"
        >
          Rentable
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="exp_pajak" dataSort csvHeader="Tax Expiry" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } dataFormat = {this.dateFormatExpPajak.bind(this)} csvFormat={this.dateFormatExpPajak.bind(this)} width="10%">Tax Expiry</TableHeaderColumn>
              <TableHeaderColumn dataField="exp_kir" dataSort csvHeader="PVI Expiry" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } dataFormat = {this.dateFormatExpKir.bind(this)} csvFormat={this.dateFormatExpKir.bind(this)} width="10%">PVI Expiry</TableHeaderColumn> */}
        {/* <TableHeaderColumn dataField="vehicle_id_number" dataSort csvHeader="Vehicle Identity Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">VIN</TableHeaderColumn> */}
        {/* <TableHeaderColumn dataField="created_on" dataSort csvHeader="Created On" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Created On</TableHeaderColumn> */}
        {/* <TableHeaderColumn dataField="action" export={false} dataFormat={ this.reportFormatter.bind(this) } width="15%">Report</TableHeaderColumn> */}
        <TableHeaderColumn
          dataField="action"
          export={false}
          dataFormat={this.buttonFormatter.bind(this)}
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="30%"
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  regWithDoorNumber = (cell, row) => {
    return (
      <div>{`${cell} ${row.door_number ? `(${row.door_number})` : ""}`}</div>
    );
  };

  handleChangeOBDType = (selectedOBDType) => {
    this.setState({ selectedOBDType });
    // console.log(`Option selected:`, selectedOBDType);
  };

  viewBindOBD = () => {
    const { selectedOBDType } = this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">OBD Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedOBDType}
              onChange={this.handleChangeOBDType}
              options={this.state.obdTypeList}
              disabled={this.state.isBinding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">OBD Serial Code</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="bindOBDCode"
              placeholder="T01XLATQ"
            />
          </Col>
        </FormGroup>
        {this.state.isBinding ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isBinding} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => this.handleBindOBD()}
              size="sm"
              color="primary"
              disabled={this.state.isAdding}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseBindOBD()}
              size="sm"
              color="success"
              disabled={this.state.isBinding}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  viewUnbindOBD = () => {
    var self = this;
    const { selectedRegNumber, selectedId, selectedOBDCode } = this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fleet Registration Number: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedRegNumber}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">OBD Serial Number: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedOBDCode}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="12">
            <Label htmlFor="text-input">
              Do you want to unbind this OBD from the fleet?{" "}
            </Label>
          </Col>
        </FormGroup>

        {this.state.isUnbinding ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isUnbinding} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => self.handleUnbindOBD(selectedId)}
              size="sm"
              color="danger"
              disabled={self.state.isUnbinding}
            >
              <i className="fa fa-trash"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseUnbindOBD()}
              size="sm"
              color="success"
              disabled={self.state.isUnbinding}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(25), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ));
  };

  loadPropertiesDatatoSelect = () => {
    var list = [];
    //For General Type
    if (this.props.carGeneralTypeTableData.length !== 0) {
      var carGeneralTypeData = this.props.carGeneralTypeTableData.result;

      carGeneralTypeData.forEach((p, index) => {
        var carGeneralData = {
          label: carGeneralTypeData[index].name,
          value: carGeneralTypeData[index].id,
        };
        list.push(carGeneralData);
      });
    }
    this.setState({ generalTypeList: list });
    // console.log(list)

    //For Car Brands
    list = [];
    if (this.props.carBrandsTableData.length !== 0) {
      var carBrandsData = this.props.carBrandsTableData.result;

      carBrandsData.forEach((p, index) => {
        var carBrandData = {
          label: carBrandsData[index].name,
          value: carBrandsData[index].id,
        };
        list.push(carBrandData);
      });
    }
    this.setState({ brandsList: list });

    //For Car Type
    list = [];
    if (this.props.carTypeTableData.length !== 0) {
      var carTypeData = this.props.carTypeTableData.result;

      carTypeData.forEach((p, index) => {
        var carType = {
          label: carTypeData[index].name,
          value: carTypeData[index].id,
        };
        list.push(carType);
      });
    }
    this.setState({ typeList: list });

    //Fuel
    list = [];
    if (this.props.carFuelTableData.length !== 0) {
      var carFuelData = this.props.carFuelTableData.result;

      carFuelData.forEach((p, index) => {
        var carFuel = {
          label: carFuelData[index].name,
          value: carFuelData[index].id,
        };
        list.push(carFuel);
      });
    }
    this.setState({ fuelList: list });
  };

  handleChangeGeneralType = (selectedGeneralType) => {
    this.setState({ selectedGeneralType });
    // console.log(`Option selected:`, this.state.selectedRow);
  };

  handleChangeBrands = (selectedBrands) => {
    this.setState({ selectedBrands });
    // console.log(`Option selected:`, selectedBrands);
  };

  handleChangeType = (selectedType) => {
    this.setState({ selectedType });
    // console.log(`Option selected:`, selectedType);
  };

  handleChangeFuel = (selectedFuel) => {
    this.setState({ selectedFuel });
    // console.log(`Option selected:`, selectedFuel);
  };

  onChangeRegYear = (e) => {
    this.setState({
      reg_year: e.target.value,
    });
    // console.log("selected value:", e.target.value);
  };

  onChangeManYear = (e) => {
    this.setState({
      manufacture_year: e.target.value,
    });
    // console.log("selected value:", e.target.value);
  };

  handleChangeTaxExpiry = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      taxDate: _date,
      taxExpiryDate: convertedDate,
    });
  };

  handleChangePVIExpiry = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      pviDate: _date,
      pviExpiryDate: convertedDate,
    });
  };

  viewEditFleet = () => {
    const {
      selectedRow,
      isEditing,
      selectedGeneralType,
      selectedBrands,
      selectedFuel,
      selectedType,
      fuel_consumption,
      odometer,
      reg_year,
      manufacture_year,
      tkm_cost,
    } = this.state;
    return (
      <div className="container animated fadeIn">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Car General Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedGeneralType}
              onChange={this.handleChangeGeneralType}
              options={this.state.generalTypeList}
              disabled={isEditing}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Car Brands</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedBrands}
              onChange={this.handleChangeBrands}
              options={this.state.brandsList}
              disabled={isEditing}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Car Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedType}
              onChange={this.handleChangeType}
              options={this.state.typeList}
              disabled={isEditing}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedFuel}
              onChange={this.handleChangeFuel}
              options={this.state.fuelList}
              disabled={isEditing}
            />
          </Col>
        </FormGroup>
        {/* disini input image */}
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Pictures</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent loading={this.state.loadingRemoteImages}>
              <>
                {this.state.inputImageValue.map((item, index) => (
                  <React.Fragment key={index}>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <InputImage
                              value={item.image}
                              onChange={(result) =>
                                this.setState({
                                  inputImageValue: [
                                    ...this.state.inputImageValue.map(
                                      (subitem, subindex) =>
                                        subindex === index
                                          ? {
                                              ...subitem,
                                              image: result,
                                            }
                                          : subitem
                                    ),
                                  ],
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Input
                              type="text"
                              value={item.desc}
                              placeholder="Description"
                              onChange={(e) =>
                                this.setState({
                                  inputImageValue: [
                                    ...this.state.inputImageValue.map(
                                      (subitem, subindex) =>
                                        subindex === index
                                          ? {
                                              ...subitem,
                                              desc: e.currentTarget.value,
                                            }
                                          : subitem
                                    ),
                                  ],
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {this.state.inputImageValue.length > 1 &&
                    typeof item.image !== "string" ? (
                      <Row>
                        <Col>
                          <Button
                            size="small"
                            onClick={() =>
                              this.setState({
                                inputImageValue: [
                                  ...this.state.inputImageValue.filter(
                                    (subitem, subindex) => subindex !== index
                                  ),
                                ],
                              })
                            }
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    <hr />
                  </React.Fragment>
                ))}
                {this.state.inputImageValue.length < 3 ? (
                  <Row>
                    <Col>
                      <Button
                        size="small"
                        onClick={() =>
                          this.setState({
                            inputImageValue: [
                              ...this.state.inputImageValue,
                              {
                                image: null,
                                desc: "",
                              },
                            ],
                          })
                        }
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </>
            </LoadingContent>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Registration Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="editRegNumber"
              value={selectedRow.reg_number}
              placeholder="B2121UKL"
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Door Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="editDoorNumber"
              onChange={(e) => this.setState({ door_number: e.target.value })}
              value={this.state.door_number}
              placeholder="e.g. T123"
              maxLength="9"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Registration Year</Label>
          </Col>
          <Col xs="12" md="9">
            <select
              onChange={this.onChangeRegYear}
              disabled={isEditing}
              value={reg_year}
            >
              {this.getDropList()}
            </select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Manufacture Year</Label>
          </Col>
          <Col xs="12" md="9">
            <select
              onChange={this.onChangeManYear}
              disabled={isEditing}
              value={manufacture_year}
            >
              {this.getDropList()}
            </select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Cylinder Capacity</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="editCylCap"
              placeholder="1200"
              onChange={(e) =>
                this.setState({ selectedRow: { cyl_cap: e.target.value } })
              }
              disabled={isEditing}
              maxLength="5"
              value={selectedRow.cyl_cap}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">VIN</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="editVIN"
              placeholder="JKHA820AVD713"
              disabled
              maxLength="25"
              value={selectedRow.vehicle_id_number}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Engine Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="editEngineNumber"
              placeholder="1K14B32JHSS08"
              disabled
              maxLength="25"
              value={selectedRow.engine_number}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Consumption (km/L)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="fuelConsumption"
              placeholder="10"
              disabled={isEditing}
              value={fuel_consumption}
              onChange={this.handleChangeFuelConsumption}
              pattern="[+-]?([0-9]*[.])?[0-9]+"
              autoComplete="off"
            />
            <div className="invalid-feedback d-block">
              {this.state.fuel_consumptionerror}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Odometer (last in km)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="odometer"
              placeholder="10129.2"
              disabled={isEditing}
              value={odometer}
              onChange={this.handleChangeOdometer}
              pattern="[+-]?([0-9]*[.])?[0-9]+"
              autoComplete="off"
            />
            <div className="invalid-feedback d-block">
              {this.state.odometerError}
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Tax Expiry</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.taxDate}
              onChange={this.handleChangeTaxExpiry}
              placeholderText={this.state.selectedRow.exp_pajak}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">PVI Expiry</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.pviDate}
              onChange={this.handleChangePVIExpiry}
              placeholderText={this.state.selectedRow.exp_kir}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <h6>Distance Matrix</h6>
        <br />
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Max Load (in kg)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="editMaxLoad"
              placeholder={this.state.max_load}
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.max_load}
              onChange={(e) => this.setState({ max_load: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Manual Speed Reduction</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="editMtSpeed"
              placeholder={this.state.mt_speed_reduction}
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.mt_speed_reduction}
              onChange={(e) =>
                this.setState({ mt_speed_reduction: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Rest Interval (in Minutes)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="editRestInterval"
              placeholder={this.state.rest_interval}
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.rest_interval}
              onChange={(e) => this.setState({ rest_interval: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">
              Interval Between Rest (in Minutes)
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="editIntervalBetween"
              placeholder={this.state.interval_between_rest}
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.interval_between_rest}
              onChange={(e) =>
                this.setState({ interval_between_rest: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        {/* Fuel Section */}
        <dd>
          This section will allow you to fine tune the fuel report. To maximise
          the result, please contact automa's certified technician.
        </dd>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Full Tank Voltage (in v)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addFuelTankVoltage"
              placeholder="0.123"
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.full_tank_voltage}
              onChange={(e) =>
                this.setState({ full_tank_voltage: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Empty Tank Voltage (in v)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addEmptyTankVoltage"
              placeholder="0.008"
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.empty_tank_voltage}
              onChange={(e) =>
                this.setState({ empty_tank_voltage: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Tank Capacity (in L)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addFuelTankCapacity"
              placeholder="40"
              min="0"
              max="500"
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.fuel_tank_capacity}
              onChange={(e) =>
                this.setState({ fuel_tank_capacity: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Alert Sensitivity</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addFuelAlertSensitivity"
              placeholder="100"
              min="0"
              max="100"
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.fuel_alert_sensitivity}
              onChange={(e) =>
                this.setState({ fuel_alert_sensitivity: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={3}>
              <Label htmlFor="">QR Solar</Label>
          </Col>
          <Col xs={12} md={9}>
            <InputImage
              value={this.state.inputImageQrSolar}
              onChange={(result) =>
                this.setState({
                  inputImageQrSolar: result,
                })
              }
              onRemove={(dt, idx) => {
                this.setState({
                  inputImageQrSolar: null,
                });
              }}
            />
          </Col>
        </FormGroup>
        <hr />
        <h5>TKM and Carbon Emission Section</h5>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Empty Weight (in Kg)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addEmptyWeight"
              placeholder="40"
              min="0"
              max="20000"
              disabled={this.state.isEditing}
              maxLength="5"
              value={this.state.empty_weight}
              onChange={(e) => this.setState({ empty_weight: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">TKM Cost</Label>
          </Col>
          <Col xs="12" md="9">
            <NumberFormat
              value={tkm_cost}
              thousandSeparator={true}
              disabled={isEditing}
              prefix={"Rp. "}
              onValueChange={(values) => {
                // eslint-disable-next-line
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.setState({ tkm_cost: value });
              }}
            />
          </Col>
        </FormGroup>
        {isEditing ? (
          <center>
            <BarLoader color={"#123abc"} loading={isEditing} /> <br /> Saving
            your data.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => this.handleEdit()}
              size="sm"
              color="primary"
              disabled={isEditing}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseEdit()}
              size="sm"
              color="success"
              disabled={isEditing}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleEditNext = (_id, dtValue, imgResp) => {
    let self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    axios({
      method: "post",
      url: API_LINKS.FLEET_EDIT_URL, //Please change when it's ready
      headers: {
        Authorization: auth,
      },
      data: {
        ...dtValue,
        qr_link_solar: imgResp
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            onFetchAll(
              {
                url: API_LINKS.FLEET_INPUT_IMAGE_URL,
                data: this.state.inputImageValue
                  .filter((item) => typeof item.image !== "string")
                  .map((item) => ({
                    file: item.image,
                    image_desc: item.desc,
                    id_fleet: _id,
                    type_image: 1,
                  })),
                type: "form",
              },
              {
                beforeSend: () => null,
                success: () => {
                  self.setState({
                    selectedRegYear: 0,
                    selectedManYear: 0,
                    selectedBrands: {},
                    selectedFuel: {},
                    selectedGeneralType: {},
                    selectedType: {},
                    selectedRow: {},
                    odometer: 0,
                    fuelConsumption: 0.0,
                    isEditing: false,
                  });
                  toast.success("Data successfully edited!");

                  this.props.fetchFleetListTable();
                  self.toggleCloseEdit();
                },
                error: () => {
                  self.setState({
                    selectedRegYear: 0,
                    selectedManYear: 0,
                    selectedBrands: {},
                    selectedFuel: {},
                    selectedGeneralType: {},
                    selectedType: {},
                    selectedRow: {},
                    odometer: 0,
                    fuelConsumption: 0.0,
                    isEditing: false,
                  });
                  toast.success("Data successfully edited!");

                  this.props.fetchFleetListTable();
                  self.toggleCloseEdit();
                },
              }
            );
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isEditing: false,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isEditing: false,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            self.setState({
              isEditing: false,
            });
            return toast.error(error.response.data.message);
          } else {
            self.setState({
              isEditing: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isEditing: false,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          self.setState({
            isEditing: false,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  }

  handleEdit = () => {
    this.setState({ isEditing: true });
    var self = this;

    //Variables for text input
    var _id = self.state.selectedId;
    var _carGeneralType = self.state.selectedGeneralType.value;
    var _carBrands = self.state.selectedBrands.value;
    var _carType = self.state.selectedType.value;
    var _fuelType = self.state.selectedFuel.value;
    var _door_number = this.state.door_number;
    // var _regNumber = document.querySelector('#addRegNumber').value;
    var _regYear = self.state.reg_year;
    var _manYear = self.state.manufacture_year;
    var _cylCap = self.state.selectedRow.cyl_cap;
    // var _VIN = document.querySelector('#addVIN').value;
    // var _engineNumber = document.querySelector('#addEngineNumber').value;
    var _fuelConsumption;
    var _odometer;
    var _expPajak = this.state.taxExpiryDate;
    var _expKir = this.state.pviExpiryDate;
    var _maxLoad = this.state.max_load;
    var _speedRed = this.state.mt_speed_reduction;
    var _restInt = this.state.rest_interval;
    var _intBetween = this.state.interval_between_rest;
    // Fuel
    var _fullTankVoltage;
    var _emptyTankVoltage;
    var _fuelTankCapacity;
    var _fuelAlertSensitivity;

    // TKM and Carbon Emission
    var _emptyWeight = this.state.empty_weight;
    var _tkmCost = this.state.tkm_cost;

    if (
      this.state.fuel_consumption !== null ||
      this.state.fuel_consumption !== undefined ||
      this.state.fuel_consumption > 0
    ) {
      _fuelConsumption = this.state.fuel_consumption;
    } else {
      _fuelConsumption = 0;
    }

    if (
      this.state.odometer !== null ||
      this.state.odometer !== undefined ||
      this.state.odometer > 0
    ) {
      _odometer = this.state.odometer;
    } else {
      _odometer = 0;
    }

    // Fuel
    if (
      this.state.full_tank_voltage !== null ||
      this.state.full_tank_voltage !== undefined ||
      this.state.full_tank_voltage > 0
    ) {
      _fullTankVoltage = this.state.full_tank_voltage;
    } else {
      _fullTankVoltage = 0;
    }

    if (
      this.state.empty_tank_voltage !== null ||
      this.state.empty_tank_voltage !== undefined ||
      this.state.empty_tank_voltage > 0
    ) {
      _emptyTankVoltage = this.state.empty_tank_voltage;
    } else {
      _emptyTankVoltage = 0;
    }

    if (
      this.state.fuel_tank_capacity !== null ||
      this.state.fuel_tank_capacity !== undefined ||
      this.state.fuel_tank_capacity > 0
    ) {
      _fuelTankCapacity = this.state.fuel_tank_capacity;
    } else {
      _fuelTankCapacity = 0;
    }

    if (
      this.state.fuel_alert_sensitivity !== null ||
      this.state.fuel_alert_sensitivity !== undefined ||
      this.state.fuel_alert_sensitivity > 0
    ) {
      _fuelAlertSensitivity = this.state.fuel_alert_sensitivity;
    } else {
      _fuelAlertSensitivity = 0;
    }

    if (_carGeneralType === "" || _carGeneralType === null) {
      self.setState({ isEditing: false });
      toast.error("Car general type is invalid!!");
    } else if (_carBrands === "" || _carBrands === null) {
      self.setState({ isEditing: false });
      toast.error("Car brands is invalid!");
    } else if (_carType === "" || _carType === null) {
      self.setState({ isEditing: false });
      toast.error("Car type is invalid!");
    } else if (_fuelType === "" || _fuelType === null) {
      self.setState({ isEditing: false });
      toast.error("Fuel type is invalid!");
    } else if (_regYear === "" || _regYear === null) {
      self.setState({ isEditing: false });
      toast.error("Registration Year is invalid!");
    } else {

      const dtValue = {
        id: _id,
        id_car_general_type: _carGeneralType,
        id_car_brands: _carBrands,
        id_car_type: _carType,
        // reg_number: _regNumber,
        reg_year: _regYear,
        manufacture_year: _manYear,
        cyl_cap: _cylCap,
        // vehicle_id_number: _VIN,
        // engine_number: _engineNumber,
        id_fuel_type: _fuelType,
        fuel_consumption: _fuelConsumption,
        odometer: _odometer,
        exp_pajak: _expPajak,
        exp_kir: _expKir,
        max_tonnage: _maxLoad,
        mt_speed_reduction: _speedRed,
        rest_interval: _restInt,
        interval_between_rest: _intBetween,
        full_tank_voltage: _fullTankVoltage,
        empty_tank_voltage: _emptyTankVoltage,
        fuel_tank_capacity: _fuelTankCapacity,
        fuel_alert_sensitivity: _fuelAlertSensitivity,
        tkm_cost: _tkmCost,
        empty_weight: _emptyWeight,
        door_number: _door_number,
      }

      if(this.state.inputImageQrSolar === null || typeof this.state.inputImageQrSolar === "string"){
        this.handleEditNext(_id, dtValue, this.state.inputImageQrSolar)
      }
      else{
        onFetch({
          url: API_LINKS.UPLOAD_IMAGE_FOR_EDIT,
          method: "post",
          type: "formdata",
          data: {
            file: this.state.inputImageQrSolar, // Menggunakan asset_picture yang sudah diubah (jika perlu)
            image_desc: "",
          }
        }, {
          beforeSend: ()=> null,
          success:(data)=>{
              this.handleEditNext(_id, dtValue, !!data ? `${data.data.result.link}` : null)
          },
          error:()=>{
            this.handleEditNext(_id, dtValue, null)
          }
        });
      }
    }
  };

  viewDeleteFleet = () => {
    var self = this;
    const { selectedName, selectedId } = this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Registration Number: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedName}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="12">
            <Label htmlFor="text-input">
              Do you want to delete this fleet?{" "}
            </Label>
          </Col>
        </FormGroup>

        {this.props.inProgressDelete ? (
          <center>
            <BarLoader
              color={"#123abc"}
              loading={this.props.inProgressDelete}
            />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => self.handleDelete(selectedId)}
              size="sm"
              color="danger"
              disabled={self.props.inProgressDelete || !!this.state.selectedIdObd}
            >
              <i className="fa fa-trash"></i> Submit {!!this.state.selectedIdObd?"(Unbind OBD first)":null}
            </Button>
            <Button
              onClick={() => this.toggleCloseDelete()}
              size="sm"
              color="success"
              disabled={self.props.inProgressDelete}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleChangeStartDate = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(_date)
    // console.log(convertedDate)
    this.setState({
      startdate: _date,
      convStartDate: convertedDate,
    });
  };

  handleChangeEndDate = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      enddate: _date,
      convEndDate: convertedDate,
    });
  };

  handleChangeFuelUsage = (e) => {
    const target = e.target;
    if (target.validationMessage === "Please match the requested format.") {
      this.setState({
        fuelUsage: target.value,
        fuelUsageError: "Invalid format!",
      });
    } else {
      this.setState({
        fuelUsage: target.value,
        fuelUsageError: target.validationMessage,
      });
    }
  };

  handleChangeFuelConsumption = (e) => {
    const target = e.target;
    if (target.validationMessage === "Please match the requested format.") {
      this.setState({
        fuel_consumption: target.value,
        fuel_consumptionerror: "Invalid format!",
      });
    } else {
      this.setState({
        fuel_consumption: target.value,
        fuel_consumptionerror: target.validationMessage,
      });
    }
  };

  handleChangeOdometer = (e) => {
    const target = e.target;
    if (target.validationMessage === "Please match the requested format.") {
      this.setState({
        odometer: target.value,
        odometerError: "Invalid format!",
      });
    } else {
      this.setState({
        odometer: target.value,
        odometerError: target.validationMessage,
      });
    }
  };

  handleChangeFuelCost = (e) => {
    const target = e.target;
    if (target.validationMessage === "Please match the requested format.") {
      this.setState({
        fuelCost: target.value,
        fuelCostError: "Invalid format!",
      });
    } else {
      this.setState({
        fuelCost: target.value,
        fuelCostError: target.validationMessage,
      });
    }
  };
  dayRangeBasedOnSubs = () => {
    if (this.context.can("create", "max_5_days@send_report_subs")) return 5;

    if (this.context.can("create", "max_30_days@send_report_subs")) return 30;

    if (this.context.can("create", "max_60_days@send_report_subs")) return 60;

    if (this.context.can("create", "max_90_days@send_report_subs")) return 90;

    if (this.context.can("create", "max_365_days@send_report_subs")) return 365;
  };
  viewSendReport = () => {
    var self = this;
    const { selectedRegNumber } = this.state;
    let maxDate = new Date(this.state.startdate);
    maxDate.setDate(maxDate.getDate() + this.dayRangeBasedOnSubs());
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Registration Number: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedRegNumber}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Start date (00:00): </Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.startdate}
              onChange={this.handleChangeStartDate}
              placeholderText="Start date"
              disabled={this.state.isSending}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">End date (23:59): </Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.enddate}
              minDate={this.state.startdate}
              maxDate={maxDate}
              onChange={this.handleChangeEndDate}
              placeholderText="End date"
              disabled={this.state.isSending || !this.state.startdate}
            />
          </Col>
        </FormGroup>
        {/* <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Fuel Usage (in L)</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="fuelUsage" placeholder="10.23" disabled={isSending} value={this.state.fuelUsage} onChange={this.handleChangeFuelUsage} pattern="\-?\d+\.\d+" autoComplete="off"/>
                        <div className="invalid-feedback d-block">
                            {this.state.fuelUsageError}
                        </div>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Fuel Cost (per L)</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="fuelCost" placeholder="10.23" disabled={isSending} value={this.state.fuelCost} onChange={this.handleChangeFuelCost} pattern="[+-]?([0-9]*[.])?[0-9]+" autoComplete="off"/>
                        <div className="invalid-feedback d-block">
                            {this.state.fuelCostError}
                        </div>
                    </Col>
                </FormGroup>  */}
        <FormGroup row>
          <Col xs="12" md="12">
            <Label htmlFor="text-input">Send report to your email? </Label>
          </Col>
        </FormGroup>

        {this.state.isSending ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isSending} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => self.handleSendReport()}
              size="sm"
              color="primary"
              disabled={self.state.isSending}
            >
              <i className="fa fa-envelope"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseSendReport()}
              size="sm"
              color="success"
              disabled={self.state.isSending}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleBindOBD = () => {
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    var _selectedFleetID = this.state.selectedFleetID;
    var _selectedOBDType = this.state.selectedOBDType.value;
    var _obdCode = document.querySelector("#bindOBDCode").value;

    this.setState({
      isBinding: true,
    });

    axios({
      method: "post",
      url: API_LINKS.OBD_BINDING_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        id_obd: _obdCode,
        id_obd_type: _selectedOBDType,
        id_fleet: _selectedFleetID,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            self.setState({
              isBinding: false,
            });
            toast.success("Data saved!");
            self.props.fetchFleetListTable();
            self.toggleCloseBindOBD();
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isBinding: false,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isBinding: false,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            self.setState({
              isBinding: false,
            });
            return toast.error(error.response.data.message);
          } else {
            self.setState({
              isBinding: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isBinding: false,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          self.setState({
            isBinding: false,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };

  handleSendReport = () => {
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    var _selectedFleetID = this.state.selectedFleetID;
    var _startDate = this.state.convStartDate;
    var _endDate = this.state.convEndDate;
    // var _fuelCost
    // var _fuelUsage

    // if(this.state.fuelCost !== null || this.state.fuelCost !== undefined || this.state.fuelCost > 0) {
    //     _fuelCost = this.state.fuelCost
    // } else {
    //     _fuelCost = 0
    // }

    // if(this.state.fuelUsage !== null || this.state.fuelUsage !== undefined || this.state.fuelUsage > 0) {
    //     _fuelUsage = this.state.fuelUsage
    // } else {
    //     _fuelUsage = 0
    // }

    this.setState({
      isSending: true,
    });

    axios({
      method: "post",
      url: API_LINKS.REPORT_DAILY_SUM,
      headers: {
        Authorization: auth,
      },
      data: {
        id: _selectedFleetID,
        start_date: _startDate,
        end_date: _endDate,
        // fuel_usage: _fuelUsage,
        // fuel_cost: _fuelCost
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            self.setState({
              isSending: false,
            });
            toast.success(`Report send to ${localStorage.AutomaEml}!`);
            self.props.fetchFleetListTable();
            self.toggleCloseSendReport();
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isSending: false,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isSending: false,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            self.setState({
              isSending: false,
            });
            return toast.error(error.response.data.message);
          } else {
            self.setState({
              isSending: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isSending: false,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          self.setState({
            isSending: false,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };

  handleUnbindOBD = (_id) => {
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    this.setState({
      isUnbinding: true,
    });

    axios({
      method: "post",
      url: API_LINKS.OBD_UNBINDING_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        id_fleet: _id,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            self.setState({
              isUnbinding: false,
            });
            toast.success("Successfully unbind OBD!");
            self.props.fetchFleetListTable();
            self.toggleCloseUnbindOBD();
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isUnbinding: false,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isUnbinding: false,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            self.setState({
              isUnbinding: false,
            });
            return toast.error(error.response.data.message);
          } else {
            self.setState({
              isUnbinding: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isUnbinding: false,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          self.setState({
            isUnbinding: false,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };

  handleDelete = (_id) => {
    this.props.fetchDeleteItem("F", _id);
    this.toggleCloseDelete();
    sleep(500).then(() => {
      // Do something after the sleep!
      this.props.fetchFleetListTable();
    });
  };

  isFleetListTableSuccess = (counter = 1) => {
    const { successFleetFetch } = this.props;
    if (counter === 5) return false;
    if (successFleetFetch) return true;
    return this.isFleetListTableSuccess(counter + 1);
  };

  render() {
    return (
      <div className="animated fadeIn">
        {this.props.inProgressFleetList ? (
          <center>
            <BarLoader
              color={"#123abc"}
              loading={this.props.inProgressFleetList}
            />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : this.props.successFleetFetch ? (
          this.showDataTable()
        ) : (
          <center>
            Error fetching your data... <br />
            <br />{" "}
            <Button
              className="btn btn-primary"
              color="primary"
              size="md"
              disabled={this.props.inProgress}
              onClick={() => this.props.fetchFleetListTable()}
            >
              <i className="fa fa-refresh"></i> Try again
            </Button>
          </center>
        )}

        {/* Modal for Binding OBD */}
        <Modal
          isOpen={this.state.modalBindOBD}
          toggle={this.toggleOpenBindOBD}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseBindOBD}>
            Fleet {this.state.selectedRegNumber} x OBD Binding
          </ModalHeader>
          <ModalBody>{this.viewBindOBD()}</ModalBody>
        </Modal>

        {/* Modal for Send */}
        <Modal
          isOpen={this.state.modalEdit}
          toggle={this.toggleOpenEdit}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseEdit}>
            Edit Fleet Data
          </ModalHeader>
          <ModalBody>{this.viewEditFleet()}</ModalBody>
        </Modal>

        {/* Modal for Send */}
        <Modal
          isOpen={this.state.modalSendReport}
          toggle={this.toggleOpenSendReport}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseSendReport}>
            Send Report
          </ModalHeader>
          <ModalBody>{this.viewSendReport()}</ModalBody>
        </Modal>

        {/* Modal for Unbinding OBD */}
        <Modal
          isOpen={this.state.modalUnbindOBD}
          toggle={this.toggleOpenUnbindOBD}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseUnbindOBD}>
            OBD Unbinding
          </ModalHeader>
          <ModalBody>{this.viewUnbindOBD()}</ModalBody>
        </Modal>

        {/* Modal for Delete Place */}
        <Modal
          isOpen={this.state.modalDelete}
          toggle={this.toggleOpenDelete}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseDelete}>
            Delete Fleet
          </ModalHeader>
          <ModalBody>{this.viewDeleteFleet()}</ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalMaintenanceBinding}
          toggle={this.toggleOpenMaintenanceBinding}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggleCloseMaintenanceBinding}>
            Maintenance Config of <b>{this.state.selectedRegNumber}</b>
          </ModalHeader>
          <ModalBody>
            <MaintenanceBinding selectedFleetId={this.state.selectedId} />
          </ModalBody>
        </Modal>
        <Modal
          size="lg"
          isOpen={!!this.state.modalBindCheckSheet}
          toggle={() => this.toggleBindCheckSheet()}
          className={this.props.className}
        >
          <ModalHeader toggle={() => this.toggleBindCheckSheet()}>
            Check Sheet Weekly
          </ModalHeader>
          <ModalBody>
            <CheckSheetWeekly
              fleet={!!this.state.modalBindCheckSheet?this.state.modalBindCheckSheet.fleet:null}
              isEdit={false}
              onClose={() => {this.toggleBindCheckSheet(); this.getAllCheckSheet();}}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    successFleetFetch: state.fleetListTableStore.success,
    inProgressFleetList: state.fleetListTableStore.inProgress,
    obdTypeTableData: state.obdTypeTableStore.obdTypeTableData,
    inProgressOBDType: state.obdTypeTableStore.inProgress,
    deleteItemData: state.deleteStore.deleteItemData,
    inProgressDelete: state.deleteStore.inProgress,
    carGeneralTypeTableData:
      state.carGeneralTypeTableStore.carGeneralTypeTableData,
    inProgressCarGeneralType: state.carGeneralTypeTableStore.inProgress,
    carBrandsTableData: state.carBrandsTableStore.carBrandsTableData,
    inProgressCarBrands: state.carBrandsTableStore.inProgress,
    carTypeTableData: state.carTypeTableStore.carTypeTableData,
    inProgressCarType: state.carTypeTableStore.inProgress,
    carFuelTableData: state.carFuelTableStore.carFuelTableData,
    inProgressCarFuel: state.carFuelTableStore.inProgress,
    authData: state.authStore.authData,
  };
};

FleetDatatable.contextType = AbilityContext;
export default connect(mapStateToProps, {
  fetchFleetListTable,
  fetchOBDTypeTable,
  fetchDeleteItem,
})(FleetDatatable);
