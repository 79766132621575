import {
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useState, useEffect } from "react";
import { useGoogleMaps } from "src/components/layouts/GoogleMapsProvider/GoogleMapsProvider";

const AssetsTrackingMap = ({ data }) => {
  const isLoaded = useGoogleMaps()
  const mapContainerStyle = {
    width: "100%",
    height: "350px",
  };
  const [showInfo, setShowInfo] = useState(null);
  const defaultCenter = { lat: -2.5489, lng: 118.0149 };

  const validData = data || []

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={defaultCenter}
      zoom={5}
    >
      {validData.map((item) =>
        item.lat && item.lng ? (
          <Marker
            key={item.id}
            position={{ lat: Number(item.lat), lng: Number(item.lng) }}
            onClick={() => {
              setShowInfo(item.id);
            }}
          >
            {showInfo === item.id && (
              <InfoWindow onCloseClick={() => setShowInfo(null)}>
                <h3>{item.car_type}</h3>
              </InfoWindow>
            )}
          </Marker>
        ) : null
      )}
      <Marker position={{ lat: -7.63554, lng: 110.804855 }}/>
    </GoogleMap>
  ) : null;
};

export default AssetsTrackingMap;
