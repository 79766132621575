import { useJsApiLoader } from "@react-google-maps/api";
import { createContext, useContext } from "react";

interface IProps {
  children: React.ReactNode;
}

const API_KEY = "AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg";

const GoogleMapsContext = createContext<boolean | null>(null);
const GoogleMapsProvider: React.FC<IProps> = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  });

  if (loadError) {
    <>
      {children}
      <p>Error loading Google Maps</p>
    </>;
  }
  if (!isLoaded) {
    return (
      <>
        {children}
        <p>Loading ...</p>
      </>
    );
  }

  return (
    <GoogleMapsContext.Provider value={isLoaded}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export default GoogleMapsProvider;

export const useGoogleMaps = () => useContext(GoogleMapsContext);