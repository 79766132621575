import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { fetchDeliveryOrdersTable } from "../../../redux/actions/transporter/aTransporter-DeliveryOrdersTable.jsx";
import DeliveryOrdersDatatable from "./DeliveryOrders-Datatable";
// import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
import moment from "moment";
import * as API_LINKS from "../../../redux/config/links";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import Select from "react-select";
import "react-select/dist/react-select.css";
import "react-toastify/dist/ReactToastify.css";
import FleetsList from "./DeliveryOrders-FleetsList.jsx";
import DriversList from "./DeliveryOrders-DriversList.jsx";
import DriverAssistantsList from "./DeliveryOrders-DriverAssistantsList.jsx";
import { daySelections } from "../../../constants/constants";
import { AbilityContext } from "../../../casl/Can";
import NumberFormat from "react-number-format";
import { SelectField } from "../../LandLord/Widgets/SelectField";
import {
  fetchRenteeContractListAll,
  fetchRenteeContractListOwnerAll,
} from "../../../redux/actions/transporter/rentee-request.act";
import { fetchRenteeFleetApproved } from "../../../redux/actions/transporter/rentee-request-impl.act";
import { fetchSettingList } from "../../../redux/actions/aUser-SettingList";
import { fetchDriverFleets } from "../../../redux/actions/transporter/aTransporter-DriversTable";

class DeliveryOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFlag: undefined,
      by_assign_date: "1",
      isAdding: false,
      modalAdd: false,
      isLoading: false,
      fleets: [],
      drivers: [],
      driverAssistants: [],
      date: "",
      assignedDoDate: "",
      tolls: false,

      // For DO Date
      startDate: "",
      rawStartDate: "",
      endDate: "",
      rawEndDate: "",

      selectedDay: {
        label: "Last 7 days",
        value: 7,
      },

      fleetSource: 1,
      renteeFleetsFetchInProgress: false,
      renteeApprovedFleets: [],
      defaultFleetsList: [],

      renteeContractSelectInProgress: true,
      renteeContractSelection: [],
      selectedContract: null,
      delivery_order_family_type: null

    };
    this.props.fetchSettingList();
  }

  componentDidUpdate(prevProps) {
    const { renteeApprovedFleets, renteeFleetsFetchInProgress } = this.props;
    const { renteeContractAllSelect, renteeContractAllSelectInProgress } =
      this.props;
    const {
      renteeContractOwnerAllSelect,
      renteeContractOwnerAllSelectInProgress,
    } = this.props;
    const { driverFleetsData } = this.props;
    if (prevProps.renteeFleetsFetchInProgress !== renteeFleetsFetchInProgress) {
      this.setState({
        renteeFleetsFetchInProgress,
      });
    }
    if (prevProps.renteeApprovedFleets !== renteeApprovedFleets) {
      this.setState({
        fleets: renteeApprovedFleets ? renteeApprovedFleets.data.result : [],
      });
    }
    if (prevProps.renteeContractAllSelect !== renteeContractAllSelect) {
      this.setState({
        renteeContractSelection: renteeContractAllSelect,
      });
    }
    if (
      prevProps.renteeContractAllSelectInProgress !==
      renteeContractAllSelectInProgress
    ) {
      this.setState({
        renteeContractSelectInProgress: renteeContractAllSelectInProgress,
      });
    }
    if (
      prevProps.renteeContractOwnerAllSelect !== renteeContractOwnerAllSelect
    ) {
      this.setState({
        renteeContractSelection: renteeContractOwnerAllSelect,
      });
    }
    if (
      prevProps.renteeContractOwnerAllSelectInProgress !==
      renteeContractOwnerAllSelectInProgress
    ) {
      this.setState({
        renteeContractSelectInProgress: renteeContractOwnerAllSelectInProgress,
      });
    }
    if (prevProps.driverFleetsData !== driverFleetsData) {
      this.setState({
        fleets: driverFleetsData,
      });
    }
    if (
      prevProps.settingListData !== this.props.settingListData &&
      this.props.settingListData.result
    ) {
      if (!this.isDriverBindingOn()) this.getFleetsList();
      this.getDriversList();
      this.getDriverAssistantsList();
    }
  }

  toggleOpenAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd,
    });

    setTimeout(()=>{
      document.querySelector("#addDeliveryOrderNumber").value=`DO/${moment().format("YYYY/MM/")}`;

      this.setState({
        rawStartDate: moment().format("YYYY-MM-DD 00:00:00"),
        rawEndDate: moment().add(1, 'days').format("YYYY-MM-DD 00:00:00")
      });

    },500)

    // this.resetComponent();
  };

  toggleCloseAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd,
    });
  };

  isDriverBindingOn = () => {
    const { settingListData } = this.props;
    if (!settingListData.result) return false;
    let filterSettingById = settingListData.result.filter(
      (s) => s.value === "9"
    );
    if (filterSettingById.length === 0) return false;
    if (filterSettingById[0].status === 1) return true;
    return false;
  };

  getFleetsList = () => {
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var defaultFleetsList = [];
    var self = this;

    axios({
      method: "post",
      url: API_LINKS.FLEET_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        // date:
        // For Parameters
      },
    }).then(function (response) {
      if (response.data.result !== null) {
        defaultFleetsList = response.data.result.map((fleet) => {
          return fleet;
        });
        // console.log(defaultFleetsList);
        self.setState({
          fleets: defaultFleetsList,
          defaultFleetsList,
        });
      }
    });
  };

  getDriversList = () => {
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var defaultDriversList = [];
    var self = this;

    axios({
      method: "post",
      url: API_LINKS.DRIVER_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        // date:
        // For Parameters
      },
    }).then(function (response) {
      if (response.data.result !== null) {
        defaultDriversList = response.data.result.map((driver) => {
          return driver;
        });
        // console.log(defaultDriversList);
        self.setState({
          drivers: defaultDriversList,
        });
      }
    });
  };

  getDriverAssistantsList = () => {
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var defaultDriverAssistantsList = [];
    var self = this;

    axios({
      method: "post",
      url: API_LINKS.DRIVER_ASSISTANT_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        // date:
        // For Parameters
      },
    }).then(function (response) {
      if (response.data.result !== null) {
        defaultDriverAssistantsList = response.data.result.map(
          (driverAssistant) => {
            return driverAssistant;
          }
        );
        // console.log(defaultDriverAssistantsList);
        self.setState({
          driverAssistants: defaultDriverAssistantsList,
        });
      }
    });
  };

  handleAssignedDoDateChange = (_date) => {
    var convertedDate = moment(_date).format("MM-DD-YYYY");
    // console.log(convertedDate)
    this.setState({
      date: _date,
      assignedDoDate: convertedDate,
    });
  };

  handleAddDeliveryOrder = () => {
    try {
      this.setState({ isAdding: true });
      var self = this;
      var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      var auth = loginCreds.token;

      //Variables for text input
      var _deliveryOrderNumber = document.querySelector(
        "#addDeliveryOrderNumber"
      ).value;
      var _deliveryOrderDesc = document.querySelector(
        "#addDeliveryOrderDesc"
      ).value;
      var _fleetSelect = document.querySelector("#addFleetSelect").value;
      var _driverSelect = document.querySelector("#addDriverSelect").value;
      var _driverAssistantSelect = document.querySelector(
        "#addDriverAssistantSelect"
      ).value;
      // var _assignedDoDate = self.state.assignedDoDate;
      var _startDate = self.state.startDate;
      var _endDate = self.state.endDate;
      var _avoidTolls = self.state.tolls;
      var _tollsFee = self.state.tolls_fee ? self.state.tolls_fee : 0;
      var _otherFee = self.state.others_fee ? self.state.others_fee : 0;
      var _weightFee = self.state.weight_fee ? self.state.weight_fee : 0;
      var _delivery_order_family_type = self.state.delivery_order_family_type;
      var id_contract = null;

      const { fleetSource, fleets } = this.state;
      let url = API_LINKS.DELIVERY_ORDERS_ADD_URL;
      if (fleetSource === 2 || fleetSource === 3) {
        url = API_LINKS.RENTEE_DO_INPUT;
        let filterByFleetSelect = fleets.filter(
          (r) => Number(r.id) === Number(_fleetSelect)
        )[0];
        _fleetSelect = filterByFleetSelect.id_fleet;
        id_contract = this.state.selectedContract.value;
      }

      if (_avoidTolls === true) {
        _avoidTolls = 1;
      } else {
        _avoidTolls = 0;
      }

      if (_deliveryOrderNumber === "" || _deliveryOrderNumber === null) {
        self.setState({ isAdding: false });
        toast.error("DO Number is invalid!");
      } else if (_deliveryOrderDesc === "" || _deliveryOrderDesc === null) {
        self.setState({ isAdding: false });
        toast.error("DO Desc is invalid!");
      } else if (_fleetSelect === "" || _fleetSelect === null) {
        self.setState({ isAdding: false });
        toast.error("Fleet is invalid!");
      } else if (_driverSelect === "" || _driverSelect === null) {
        self.setState({ isAdding: false });
        toast.error("Driver is invalid!");
      } else if (
        _driverAssistantSelect === "" ||
        _driverAssistantSelect === null
      ) {
        self.setState({ isAdding: false });
        toast.error("Driver Assistant is invalid!");
      } else if (_startDate === "" || _startDate === null) {
        self.setState({ isAdding: false });
        toast.error("DO Start Date is invalid!");
      } else if (_endDate === "" || _endDate === null) {
        self.setState({ isAdding: false });
        toast.error("DO End Date is invalid!");

        // } else if(_assignedDoDate === "" || _assignedDoDate === null) {
        //     self.setState({isAdding: false})
        //     toast.error("Assigned DO date is invalid!")
      } else {
        let data = {
          do_number: _deliveryOrderNumber,
          do_desc: _deliveryOrderDesc,
          id_fleet: _fleetSelect,
          id_driver: _driverSelect,
          id_driver_asst: _driverAssistantSelect,
          assigned_do_date: _startDate,
          end_assigned_do_date: _endDate,
          avoid_toll: _avoidTolls,
          toll_fee: _tollsFee,
          weight_fee: _weightFee,
          other_fee: _otherFee,
          delivery_order_family_type: _delivery_order_family_type
        };
        if (Number(fleetSource) === 2 || Number(fleetSource) === 3) {
          data["id_contract"] = id_contract;
        }
        axios({
          method: "post",
          url: url,
          headers: {
            Authorization: auth,
          },
          data: data,
        })
          .then((response) => {
            if (response.status === 200) {
              self.setState({
                isAdding: false,
                fleets: [],
                drivers: [],
                driverAssistants: [],
                rawStartDate: "",
                rawEndDate: "",
                startDate: "",
                endDate: "",
                tolls: false,
                delivery_order_family_type: null
                // date: '',
                // assignedDoDate: '',
              });
              if (response.data.status === "success") {
                toast.success("Data saved!");
                self.toggleCloseAdd();
                self.props.fetchDeliveryOrdersTable();
                self.getFleetsList();
                self.getDriversList();
                self.getDriverAssistantsList();
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 401) {
                self.setState({ isAdding: true });
                return toast.error("Invalid credentials! Please try again!");
              } else if (
                error.response.status === 404 ||
                error.response.status === 500
              ) {
                self.setState({ isAdding: true });
                return toast.error(
                  "Server cannot be contacted! Please ask your system administrator!"
                );
              } else if (error.response.status === 400) {
                self.setState({ isAdding: true });
                return toast.error(error.response.data.message);
              } else {
                self.setState({ isAdding: true });
                return toast.error(
                  "Something went wrong... Please try again later..."
                );
              }
            } else if (error.request) {
              self.setState({ isAdding: true });
              return toast.error("Request error! Please try again later...");
            } else {
              self.setState({ isAdding: true });
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          });
      }
    } catch (e) {
      return toast.error(e);
    }
  };

  handleStartDateChange = (_date) => {
    var convertedDate = moment(_date).format("MM-DD-YYYY HH:mm:ss");
    this.setState({
      rawStartDate: _date,
      startDate: convertedDate,
    });
  };

  handleEndDateChange = (_date) => {
    var convertedDate = moment(_date).format("MM-DD-YYYY HH:mm:ss");
    this.setState({
      rawEndDate: _date,
      endDate: convertedDate,
    });
  };

  handleChangeTolls = (tolls) => {
    this.setState({ tolls });
    this.setState({ tolls_fee: 0 });
  };

  handleChangeFamilyType = (checked) => {
    this.setState({delivery_order_family_type: checked? 1:null });
  }

  handleChangeDeliveryCost = (selectedCost) => {
    this.setState({ selectedCost });
  };

  FormDriverFleetGroupTypeOne = (props) => {
    const { renteeFleetsFetchInProgress } = this.state;
    return (
      <>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fleet</Label>
          </Col>
          {this.state.fleetSource === 2 || this.state.fleetSource === 3 ? (
            <>
              {renteeFleetsFetchInProgress ? (
                <center>
                  <BarLoader
                    color={"#123abc"}
                    loading={renteeFleetsFetchInProgress}
                  />
                  <br /> Loading.... Please wait...
                </center>
              ) : (
                <>
                  <Col xs="12" md="9">
                    <FleetsList state={this.state} />
                  </Col>
                </>
              )}
            </>
          ) : (
            <>
              <Col xs="12" md="9">
                <FleetsList state={this.state} />
              </Col>
            </>
          )}
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver</Label>
          </Col>
          <Col xs="12" md="9">
            <DriversList
              state={this.state}
              isDriverBindingOn={this.isDriverBindingOn()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Assistant</Label>
          </Col>
          <Col xs="12" md="9">
            <DriverAssistantsList state={this.state} />
          </Col>
        </FormGroup>
      </>
    );
  };

  handleDriverSelected = (idDriver) => {
    this.props.fetchDriverFleets(idDriver);
  };

  FormDriverFleetGroupTypeTwo = (props) => {
    const { driverFleetsLoading } = this.props;
    const { renteeFleetsFetchInProgress } = this.state;
    return (
      <>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver</Label>
          </Col>
          <Col xs="12" md="9">
            <DriversList
              state={this.state}
              onDriverSelected={this.handleDriverSelected}
              isDriverBindingOn={this.isDriverBindingOn()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Assistant</Label>
          </Col>
          <Col xs="12" md="9">
            <DriverAssistantsList state={this.state} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fleet</Label>
          </Col>
          {this.state.fleetSource === 2 || this.state.fleetSource === 3 ? (
            <>
              {renteeFleetsFetchInProgress ? (
                <center>
                  <BarLoader
                    color={"#123abc"}
                    loading={renteeFleetsFetchInProgress}
                  />
                  <br /> Loading.... Please wait...
                </center>
              ) : (
                <>
                  <Col xs="12" md="9">
                    <FleetsList state={this.state} />
                  </Col>
                </>
              )}
            </>
          ) : (
            <>
              {driverFleetsLoading === "loading" && (
                <Col xs="5" md="5">
                  <center>
                    <BarLoader color={"#123abc"} loading={true} />
                    <br /> Loading.... Please wait...
                  </center>
                </Col>
              )}
              {(!driverFleetsLoading || driverFleetsLoading === "loaded") && (
                <Col xs="12" md="9">
                  <FleetsList state={this.state} />
                </Col>
              )}
            </>
          )}
        </FormGroup>
      </>
    );
  };

  viewAddDeliveryOrder = () => {
    var yesterday = moment().subtract(1, "day");
    var disabledDays = function (current) {
      return current.isAfter(yesterday);
    };
    const {
      isAdding,
      renteeContractSelectInProgress,
      renteeContractSelection,
      selectedContract,
    } = this.state;
    return (
      <div className="container animated fadeIn">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">DO Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addDeliveryOrderNumber"
              placeholder="Enter DO number here"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">DO Description</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addDeliveryOrderDesc"
              placeholder="Enter the description here"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">DO Type</Label>
          </Col>
          <Col xs="9" md="9">
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() =>
                  this.setState({
                    fleetSource: 1,
                    fleets: this.state.defaultFleetsList,
                  })
                }
                active={this.state.fleetSource === 1}
                disabled={this.state.isAdding}
              >
                Normal
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.props.fetchRenteeContractListOwnerAll();
                  this.setState({
                    fleetSource: 2,
                    fleets: [],
                  });
                }}
                active={this.state.fleetSource === 2}
                disabled={this.state.isAdding}
              >
                Rentee (Owner)
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.props.fetchRenteeContractListAll();
                  this.setState({
                    fleetSource: 3,
                    fleets: [],
                  });
                }}
                active={this.state.fleetSource === 3}
                disabled={this.state.isAdding}
              >
                Rentee (Renter)
              </Button>
            </ButtonGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Allow Family Checkin in DO</Label>
          </Col>
          <Col xs="12" md="9">
            <Switch
              onChange={this.handleChangeFamilyType}
              checked={this.state.delivery_order_family_type}
            />
          </Col>
        </FormGroup>
        {this.state.fleetSource === 2 || this.state.fleetSource === 3 ? (
          <FormGroup row>
            <Col md="3">
              <Label htmlFor="text-input">Contract</Label>
            </Col>
            <Col xs="6" md="6">
              {renteeContractSelectInProgress ? (
                <center>
                  <BarLoader
                    color={"#123abc"}
                    loading={renteeContractSelectInProgress}
                  />
                  <br /> Loading.... Please wait...
                </center>
              ) : (
                <>
                  <SelectField
                    id="data_period"
                    placeholder="Select Contract "
                    options={renteeContractSelection}
                    value={selectedContract}
                    onChange={(e, selectedContract) => {
                      if (selectedContract) {
                        this.setState({ selectedContract });
                        this.props.fetchRenteeFleetApproved(
                          selectedContract.id_request
                        );
                      } else {
                        this.setState({ selectedContract: null });
                      }
                    }}
                    onBlur={() => {}}
                    isMulti={false}
                    isClearable={false}
                    backspaceRemovesValue={false}
                  />
                </>
              )}
            </Col>
          </FormGroup>
        ) : null}
        {!this.isDriverBindingOn() && <this.FormDriverFleetGroupTypeOne />}
        {this.isDriverBindingOn() && <this.FormDriverFleetGroupTypeTwo />}
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Start DO Date</Label>
          </Col>
          <Col xs="12" md="9">
            {/* <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={this.state.date}
                            onChange={this.handleAssignedDoDateChange} 
                            placeholderText="Select DO Date"
                            disabled={this.state.isAdding}/> */}
            <DateTime
              id="addStartDo"
              input="true"
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
              value={
                this.state.rawStartDate 
              }
              onChange={this.handleStartDateChange}
              isValidDate={disabledDays}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">End DO Date</Label>
          </Col>
          <Col xs="12" md="9">
            <DateTime
              id="addEndDo"
              input="true"
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
              value={
                this.state.rawEndDate
              }
              onChange={this.handleEndDateChange}
              isValidDate={disabledDays}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>

        {/* <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Cost Calculation Method</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Select
                            value={selectedCost}
                            onChange={this.handleChangeDeliveryCost}
                            options={deliveryCostCalculationSelections}
                            disabled={this.state.isAdding}
                        />
                    </Col>
                </FormGroup> */}

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Avoid Tolls</Label>
          </Col>
          <Col xs="12" md="9">
            <Switch
              onChange={this.handleChangeTolls}
              checked={this.state.tolls}
            />
          </Col>
        </FormGroup>

        {!this.state.tolls ? (
          <FormGroup row>
            <Col md="3">
              <Label htmlFor="text-input">Tolls Fee</Label>
            </Col>
            <Col xs="12" md="9">
              <NumberFormat
                value={this.state.tolls_fee}
                thousandSeparator={true}
                disabled={isAdding}
                prefix={"Rp. "}
                onValueChange={(values) => {
                  // eslint-disable-next-line
                  const { formattedValue, value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  this.setState({ tolls_fee: value });
                }}
              />
            </Col>
          </FormGroup>
        ) : (
          true
        )}

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Weighting Fee</Label>
          </Col>
          <Col xs="12" md="9">
            <NumberFormat
              value={this.state.weight_fee}
              thousandSeparator={true}
              disabled={isAdding}
              prefix={"Rp. "}
              onValueChange={(values) => {
                // eslint-disable-next-line
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.setState({ weight_fee: value });
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Others Fee</Label>
          </Col>
          <Col xs="12" md="9">
            <NumberFormat
              value={this.state.others_fee}
              thousandSeparator={true}
              disabled={isAdding}
              prefix={"Rp. "}
              onValueChange={(values) => {
                // eslint-disable-next-line
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.setState({ others_fee: value });
              }}
            />
          </Col>
        </FormGroup>

        {this.state.isAdding ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isAdding} /> <br />{" "}
            Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => this.handleAddDeliveryOrder()}
              size="sm"
              color="primary"
              disabled={this.state.isAdding}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseAdd()}
              size="sm"
              color="success"
              disabled={this.state.isAdding}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  checkRoles = () => {
    return (
      <div>
        {this.context.can(
          "view",
          "add_delivery_order_button@delivery_orders"
        ) && (
          <Button
            onClick={this.toggleOpenAdd}
            size="sm"
            color="success"
            disabled={this.props.inProgress}
          >
            <i className="fa fa-plus"></i> Add Delivery Order
          </Button>
        )}
      </div>
    );
  };

  handleChangeDay = (selectedDay) => {
    this.setState({ selectedDay });
    // console.log(`Option selected:`, selectedDay);
  };

  render() {
    const { selectedDay, by_assign_date, selectedFlag } = this.state;
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            DO Type:
            <br />
            <br />
            <div className="row">
              <div className="col-12">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value=""
                    onChange={(e) => this.setState({ selectedFlag: undefined })}
                    checked={selectedFlag === undefined ? true : false}
                  />
                  <label className="form-check-label">All</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="1"
                    onChange={(e) =>
                      this.setState({ selectedFlag: e.currentTarget.value })
                    }
                    checked={selectedFlag === "1" ? true : false}
                  />
                  <label className="form-check-label">Master</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="2"
                    onChange={(e) =>
                      this.setState({ selectedFlag: e.currentTarget.value })
                    }
                    checked={selectedFlag === "2" ? true : false}
                  />
                  <label className="form-check-label">Copy</label>
                </div>
              </div>
            </div>
            <br />
            <br />
            Filter day:
            <br />
            <br />
            <div className="row">
              {selectedDay.value !== null && (
                <div className="col-sm-1">
                  <input
                    id="do_start"
                    type="radio"
                    name="by_assign_date"
                    value="1"
                    checked={by_assign_date === "1"}
                    onChange={(e) =>
                      this.setState({
                        by_assign_date: e.currentTarget.value,
                      })
                    }
                  />{" "}
                  <label htmlFor="do_start">DO Start Date</label>
                  <br />
                  <input
                    id="do_end"
                    type="radio"
                    name="by_assign_date"
                    value="0"
                    checked={by_assign_date === "0"}
                    onChange={(e) =>
                      this.setState({
                        by_assign_date: e.currentTarget.value,
                      })
                    }
                  />{" "}
                  <label htmlFor="do_end">DO End Date</label>
                </div>
              )}
              <div className={`col-sm-${selectedDay.value === null ? 12 : 11}`}>
                <Select
                  value={selectedDay}
                  onChange={this.handleChangeDay}
                  options={daySelections}
                  disabled={this.props.inProgress}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Delivery Orders List</CardHeader>
          <CardBody>
            {this.props.successDeliveryOrdersFetch &&
            this.props.successSettingList ? (
              <div>
                <Row className="pull-right">{this.checkRoles()}</Row> <br />
                <br />
                <br />
                <br />{" "}
              </div>
            ) : (
              true
            )}
            <DeliveryOrdersDatatable
              day={selectedDay.value}
              by_assign_date={by_assign_date}
              flag={selectedFlag}
            />
          </CardBody>

          {/* Modal for Adding Cards */}
          <Modal
            isOpen={this.state.modalAdd}
            toggle={this.toggleOpenAdd}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOpenAdd}>
              Add Delivery Order
            </ModalHeader>
            <ModalBody>{this.viewAddDeliveryOrder()}</ModalBody>
            {/* <ToastContainer position="bottom-right" autoClose={7500} style={toastStyle}/> */}
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deliveryOrdersTableData:
      state.deliveryOrdersTableStore.deliveryOrdersTableData,
    inProgress: state.deliveryOrdersTableStore.inProgress,
    successDeliveryOrdersFetch: state.deliveryOrdersTableStore.success,

    renteeApprovedFleets: state.renteeApprovedFleetStore.renteeApprovedFleets,
    renteeFleetsFetchInProgress: state.renteeApprovedFleetStore.inProgress,

    renteeContractAllSelect:
      state.renteeContractAllStore.renteeContractAllSelect,
    renteeContractAllSelectInProgress: state.renteeContractAllStore.inProgress,

    renteeContractOwnerAllSelect:
      state.renteeContractOwnerAllStore.renteeContractOwnerAllSelect,
    renteeContractOwnerAllSelectInProgress:
      state.renteeContractOwnerAllStore.inProgress,

    settingListData: state.settingStore.settingListData,
    successSettingList: state.settingStore.success,

    driverFleetsData: state.driverFleetsStore.driverFleetsData,
    driverFleetsLoading: state.driverFleetsStore.loading,
  };
};

DeliveryOrders.contextType = AbilityContext;
export default connect(mapStateToProps, {
  fetchDeliveryOrdersTable,
  fetchRenteeFleetApproved,
  fetchRenteeContractListAll,
  fetchRenteeContractListOwnerAll,
  fetchSettingList,
  fetchDriverFleets,
})(DeliveryOrders);
